import * as apiState from '../../modules/api/selectors'
import {
  loadFeed,
  loadOwnedFeed,
} from '../../modules/entities/actions/feed_load'
import updateFollowing from '../../modules/entities/actions/follow'
import { loadSearch } from '../../modules/entities/actions/search'
import { selectFeedState } from '../../modules/entities/selectors'
import { FEED_SEARCH_RESULTS } from '../../modules/entities/types'
import { connect } from '../../react-redux'
import View from './content-feed'
import { FeedsType } from './types'

const perPage = 36

const getLoadAction = (
  props: FeedsType,
  page: number,
  per_page: number
): any => {
  if (props.onLoadNeeded) {
    return props.onLoadNeeded(page, per_page)
  } else if (props.feed_type === FEED_SEARCH_RESULTS) {
    return loadSearch(
      props.searchTerm || '',
      props.searchFilter,
      page,
      per_page
    )
  } else if (props.feed_id === undefined) {
    return loadFeed(props.feed_type, page, per_page, props.searchTerm)
  }
  return loadOwnedFeed(
    props.feed_type,
    props.feed_id,
    page,
    per_page,
    props.searchTerm
  )
}

const mapStateToProps = (state: any, ownProps: FeedsType): any => {
  const { feed_type, feed_id, searchTerm, includeCategories } = ownProps
  const feedState = selectFeedState(
    state,
    feed_type,
    feed_id,
    searchTerm,
    includeCategories
  )

  const request = getLoadAction(ownProps, 1, perPage)
  const isLoading = apiState.isRequestActive(state, request)
  const error = apiState.requestErrorString(state, request)

  return {
    ...feedState,
    isLoading,
    error,
    safeToload: Object.keys(state.temp.stack).length,
  }
}

const mapDispatchToProps = (dispatch: Function, props: FeedsType): any => ({
  onLoadNeeded: (page: number = 1, per_page: number = perPage): any => {
    const action = getLoadAction(props, page, per_page)
    dispatch(action)
  },

  updateFollowing: (resource: any, follow: any): any => {
    dispatch(updateFollowing(resource, follow))
  },
})

export const defaultWideLayout = {
  _: 4,
  palm_portrait: 1,
  palm_landscape: 2,
  lap: 3,
  desk: 4,
  large_desk: 6,
}

export const defaultColumnLayout = {
  _: 1,
  palm_portrait: 1,
  palm_landscape: 2,
  lap: 1,
  desk: 2,
  large_desk: 3,
}

export default connect<FeedsType & any>(
  mapStateToProps,
  mapDispatchToProps
)(View)
