import { concat, reduce, toPairs, uniq } from 'ramda'

import { selectSpecialtyMapper } from '../../modules/categories/selectors'
import * as userState from '../../modules/auth/selectors'

export const selectSuggestedSearches = state => {
  let suggestions = []

  const specialties = selectSpecialtyMapper(state)

  if (specialties) {
    let filters = state.filters
    if (filters) {
      suggestions = concat(
        suggestions,
        reduce(
          (carry, [key, enabled]) => {
            return concat(carry, [key])
          },
          [],
          toPairs(filters)
        )
      )
    }

    const user = userState.selectAuthenticatedUser(state)
    suggestions = uniq(concat(suggestions, user.specialty))

    suggestions = specialties.getUnambiguousLabelsFor(suggestions)
    suggestions.sort()

    suggestions = suggestions.slice(0, 5)
  }

  return suggestions
}
