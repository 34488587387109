import cs from 'classnames'
import { useTranslation } from 'next-i18next'
import React from 'react'

import { selectAuthenticatedUser } from '../modules/auth/selectors'
import { selectIsMobile } from '../modules/responsive/selectors'
import { useSelector } from '../react-redux'
import SearchIcon from './icons/search'
import XCircleIcon from './icons/x-circle'

type Props = {
  value: string
  placeholder?: string
  onChange: (e: React.ChangeEvent) => void
  onClearInputClick?: (e: React.MouseEvent) => void
  onBlur?: (e: React.FocusEvent) => void
  onFocus?: (e: React.FocusEvent) => void
}

const SearchInputBox = (props: Props) => {
  const { t } = useTranslation('common')

  const currentUser = useSelector(selectAuthenticatedUser)
  const isMobile = useSelector(selectIsMobile)

  const { value, placeholder } = props
  const hasValue = value && Array.isArray(value)

  return (
    !!currentUser && (
      <div
        className={cs('search-field', 'search-bar__form-field', {
          'search-field--has-value': hasValue && value.length > 0,
        })}
      >
        <label htmlFor="search-input-q" style={{ cursor: 'pointer' }}>
          <SearchIcon />
        </label>
        <input
          id="search-input-q"
          key="search-input"
          type="textbox"
          name="q"
          placeholder={
            placeholder
              ? placeholder
              : isMobile
              ? t('search.placeholder_mobile')
              : t('search.placeholder')
          }
          className="fs-unmask search-field__input search-bar__form-input"
          onFocus={props.onFocus}
          onBlur={props.onBlur}
          value={props.value}
          onChange={props.onChange}
          autoComplete="off"
        />
        <XCircleIcon onClick={props.onClearInputClick} />
      </div>
    )
  )
}

export default SearchInputBox
