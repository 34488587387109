import debounce from 'lodash/debounce'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import { useTranslation, withTranslation } from 'next-i18next'
import React from 'react'
import { connect } from 'react-redux'
import {
  selectFeedPage,
  selectFeedState,
} from '../../modules/entities/selectors'
import { SOURCE_FEED_NAMES } from '../../modules/entities/types'
import { selectIsMobile } from '../../modules/responsive/selectors'
import Button from '../buttons/button'
import CardList from '../cards/list'
import CssLoader from '../CssLoader'
import ErrorBoundary from '../ErrorBoundary'
import InfiniteScroll from '../infinite-scroll'
import { FeedListType, FeedsType, FeedsTypeState } from './types'

const FeedList = ({
  feed,
  feed_type,
  feed_id,
  layout,
  className,
  cardStyle,
  cardProps,
  isMobile,
}: FeedListType) => {
  if (feed) {
    const updatedCardProps = cardProps.resourceContext
      ? {
          ...cardProps,
          isMobile,
          resourceContext: {
            ...(cardProps.resourceContext || {}),
            feed_type,
            feed_id,
          },
        }
      : { ...cardProps, isMobile }

    const sourceFeedName = SOURCE_FEED_NAMES[feed_type]

    return (
      <CardList
        trackImpressions={!!sourceFeedName}
        feedName={sourceFeedName ? sourceFeedName : ''}
        cards={feed.entries}
        banners={feed.banners}
        layout={layout}
        className={className}
        cardStyle={cardStyle}
        cardProps={updatedCardProps}
      />
    )
  }

  return null
}

const FeedListing = connect((state: any, props: any): any => {
  const { feed_type, feed_id, searchTerm, includeCategories, page, count } =
    props
  const feedState = selectFeedPage(
    selectFeedState(state, feed_type, feed_id, searchTerm, includeCategories),
    page,
    count
  )

  return { ...feedState, isMobile: selectIsMobile(state) }
})(FeedList)

// ////////////////////////////////////////////////////////////////// FEED CLASS
class Feed extends React.Component<FeedsType, FeedsTypeState> {
  constructor(props: FeedsType, context: any) {
    super(props, context)

    this.state = {
      page: props.page,
      displayPage: props.page,
    }
  }

  componentDidMount() {
    if (this.props.feed) this.notifyLoaded()
    else this.props.onLoadNeeded(this.state.page)
  }

  componentDidUpdate(oldProps: FeedsType, oldState: any) {
    const { searchTerm, searchFilter, feed, isLoading, error } = this.props
    const { page } = this.state

    if (
      !feed ||
      page !== oldState.page ||
      searchTerm !== oldProps.searchTerm ||
      !isEqual(searchFilter, oldProps.searchFilter)
    ) {
      if (!isLoading && !error) {
        this.props.onLoadNeeded(page)
      }
    }
    if (feed && !oldProps.feed) {
      this.notifyLoaded()
    }
  }

  static defaultProps = {
    paginate: false,
    continuous: false,
    layout: {}, // set below to defaultWideLayout
    cardStyle: 'card',
    cardProps: {},
    page: 1,
    count: 6,
  }

  static defaultWideLayout = {
    _: 4,
    palm_portrait: 1,
    palm_landscape: 2,
    lap: 3,
    desk: 4,
    large_desk: 6,
  }

  static defaultColumnLayout = {
    _: 1,
    palm_portrait: 1,
    palm_landscape: 2,
    lap: 1,
    desk: 2,
    large_desk: 3,
  }

  get getPage(): number {
    return this.state.displayPage
  }

  get hasMore(): boolean {
    const { feed, paginate, count } = this.props
    return !feed || (feed && paginate && this.getPage * count < feed.total)
  }

  loadPage = (displayPage: number): any => {
    const { feed, count } = this.props
    const page = feed ? Math.ceil((count * displayPage) / feed.per_page) : 1

    const state = { ...this.state, page, displayPage }

    if (!isEqual(state, this.state)) {
      const updateState = () => {
        this.setState(state)
      }
      const debouncedUpdateState = debounce(updateState, 1000)
      debouncedUpdateState()
    }
  }

  notifyLoaded = (): any => {
    if (this.props.onLoad) {
      this.props.onLoad(this.props.feed, this.props)
    }
  }

  renderTitle = (titleID: string, feed: any): any => {
    const { t } = this.props
    if (titleID) {
      return (
        <h1>
          <>{t(titleID, { count: feed ? feed.total : 0 })}</>
        </h1>
      )
    }
  }

  renderCardList = (): any => {
    return (
      <ErrorBoundary>
        <FeedListing {...this.props} page={this.getPage} />
      </ErrorBoundary>
    )
  }

  renderLoadMoreLink = (): any => {
    const { feed, customLoadNextPage, loadMoreLabel, safeToload } = this.props

    if (feed && feed.entries.length && this.hasMore) {
      return (
        <div className="load-more">
          <Button
            className=""
            onClick={() => {
              safeToload
                ? this.loadPage(this.getPage + 1)
                : customLoadNextPage
                ? customLoadNextPage()
                : this.loadPage(this.getPage + 1)
            }}
          >
            <LoadMoreLabel loadMoreLabel={loadMoreLabel} />
          </Button>
        </div>
      )
    }
  }

  render(): React.ReactElement {
    const { isLoading, continuous, titleID, feed, emptyComponent } = this.props
    const cardList = this.renderCardList()
    const title = this.renderTitle(titleID, feed)

    const loadingSpinner = (
      <div style={{ padding: '30px 0px' }}>
        <CssLoader isLoading={true} />
      </div>
    )

    if (get(feed, 'entries', []).length === 0 && !isLoading && emptyComponent) {
      return <div>{emptyComponent()}</div>
    }

    if (feed?.total === 0) {
      return null
    }

    if (continuous) {
      return (
        <InfiniteScroll
          page={this.getPage}
          loadMore={this.loadPage}
          hasMore={this.hasMore}
          loading={isLoading}
          loader={loadingSpinner}
        >
          <div>
            {title}
            {cardList}
          </div>
        </InfiniteScroll>
      )
    }

    return (
      <div>
        {title}
        <div>
          {cardList}
          {isLoading && loadingSpinner}
          {!isLoading && this.renderLoadMoreLink()}
        </div>
      </div>
    )
  }
}

const LoadMoreLabel = ({ loadMoreLabel }): JSX.Element => {
  const { t } = useTranslation('feed')
  return loadMoreLabel ?? <>{t('feed.default_load_more')}</>
}

Feed.defaultProps.layout = Feed.defaultWideLayout

export default withTranslation()(Feed)
