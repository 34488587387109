import { concat, reduce, toPairs } from 'ramda'

// ///////////////////////////////////////////////////////////////////// ACTIONS
import { appendQuery } from '../../../url'
import { request } from '../../api/actions'
import * as schemas from '../../entities/schemas'
import * as types from '../../entities/types'
import {
  LOAD_FAILURE,
  LOAD_REQUEST,
  LOAD_SUCCESS,
  REMOVE_FEED,
} from './feed_load'

// ////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////

export function loadSearch(term, filter, page, per_page = 36) {
  const feed_type = types.FEED_SEARCH_RESULTS
  const enabledFilters = reduce(
    (carry, [filter, enabled]) => {
      if (enabled) {
        return concat(carry, [filter])
      }
      return carry
    },
    [],
    toPairs(filter)
  )

  if (enabledFilters.length > 0) {
    const url = schemas.Feeds[feed_type].url
    const fullUrl = appendQuery(
      appendQuery(url, {
        q: term,
        page,
        per_page,
      }),
      enabledFilters
        .map(filter => 'type[]=' + encodeURIComponent(filter))
        .join('&')
    )

    return request({
      debounce: true,
      clearExisting: page <= 1,
      feed_type,
      id: url,
      url: fullUrl,
      page: page,
      start: LOAD_REQUEST,
      success: {
        type: LOAD_SUCCESS,
        feed_type: feed_type,
        meta: {
          schema: schemas.getFeedSchema(feed_type),
        },
      },
      failure: LOAD_FAILURE,
    })
  }

  return {
    type: REMOVE_FEED,
    feed_type,
  }
}
