import get from 'lodash/get'
import reduce from 'lodash/reduce'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import React, { createElement, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
// ////////////////////////////////////////////////////////////////////// HELPER
import { closest } from '../../lib/dom'
import {
  selectAuthenticatedUser,
  selectIsAuthenticated,
} from '../../modules/auth/selectors'
import { FEED_SEARCH_RESULTS } from '../../modules/entities/types'
import { closeModal, openModal } from '../../modules/modal/actions'
import { selectModalVisible } from '../../modules/modal/selectors'
// ///////////////////////////////////////////////////////////////////// ACTIONS
import { selectIsMobile } from '../../modules/responsive/selectors'
import {
  clearRecentSearches,
  saveRecentSearch,
  updateFilter,
  updateTerm,
} from '../../modules/search/actions'
import {
  selectRecentSearches,
  selectSearchFilters,
  selectSearchTerm,
} from '../../modules/search/selectors'
import { selectSuggestedSearches } from '../../modules/search/suggest'
import { SEARCH_TYPE_MAIN } from '../../modules/search/types'
// ////////////////////////////////////////////////////////////////// COMPONENTS
import Breadcrumb from '../../ui/breadcrumb'
import ContentFeed from '../../ui/Feeds'
import { Checkbox } from '../../ui/forms/base'
import ScrollTrap from '../../ui/scroll-trap'
import CloseIcon from '../../ui/icons/x'
import { getBackURLFromQuery } from '../../url'
import FilterBar from './filter-bar'
import { SearchBarView } from './searchBarView'
import { SearchModal } from './SearchModal'
import { useSelector } from '../../react-redux'

// ////////////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////////////

const mapStateToPropsForEditor = state => {
  const searchTerm = selectSearchTerm(state)
  const filter = selectSearchFilters(state)
  const recentSearches =
    searchTerm.length > 0 ? [] : selectRecentSearches(state, SEARCH_TYPE_MAIN)
  const suggestedSearches =
    searchTerm.length > 0 || recentSearches.length > 0
      ? []
      : selectSuggestedSearches(state)
  const isMobile = selectIsMobile(state)
  return { suggestedSearches, recentSearches, searchTerm, filter, isMobile }
}

const mapDispatchToPropsForEditor = dispatch => ({
  saveRecentSearch(term) {
    dispatch(saveRecentSearch(SEARCH_TYPE_MAIN, term))
  },
  clearRecentSearches() {
    dispatch(clearRecentSearches(SEARCH_TYPE_MAIN))
  },
  updateTerm(term) {
    dispatch(updateTerm(term))
  },
  updateFilter(filter) {
    dispatch(updateFilter(filter))
  },
  closeModal() {
    dispatch(closeModal())
  },
})

type SearchViewProps = {
  suggestedSearches: Array<any>
  recentSearches: Array<any>
  searchTerm: string
  saveRecentSearch: Function
  clearRecentSearches: Function
  updateTerm: Function
  closeModal: Function
  updateFilter: Function
  isMobile: boolean
  onHeightChange?: (height: number) => void
  filter: Filter
}

type Filter = {
  user: boolean
  case: boolean
  group: boolean
  learning: boolean
}

function unfreezeBodyScroll() {
  document.body.classList.remove('body__freeze-scroll')
}

const SearchView = ({
  suggestedSearches,
  recentSearches,
  searchTerm,
  saveRecentSearch,
  clearRecentSearches,
  updateTerm,
  closeModal,
  updateFilter,
  isMobile,
  onHeightChange,
  filter,
}: SearchViewProps) => {
  const searchStatusRef = useRef<HTMLDivElement>(null)
  const searchResultsRef = useRef<HTMLDivElement>(null)

  const notifyHeightChange = () => {
    if (
      !isMobile &&
      searchStatusRef.current &&
      searchResultsRef.current &&
      onHeightChange
    ) {
      onHeightChange(
        searchStatusRef.current.offsetHeight +
          searchResultsRef.current.offsetHeight
      )
    }
  }

  useEffect(() => {
    notifyHeightChange()
  }, [searchStatusRef.current, searchResultsRef.current])

  useEffect(() => {
    return () => {
      unfreezeBodyScroll()
    }
  }, [])

  const selectTerm = term => {
    updateTerm(term)
  }

  const runUpdateFilter = (contentTypeName, enabled) => {
    updateFilter({ ...filter, [contentTypeName]: enabled })
  }

  const renderSearchBar = () => {
    if (isMobile) {
      return (
        <div style={{ position: 'relative' }}>
          <ResourcePageSearchBar />
          <CloseIcon
            className="modal-container__close"
            onClick={() => closeModal()}
          />
        </div>
      )
    }

    return null
  }

  const { t } = useTranslation('common')

  const renderTitle = () => {
    if (searchTerm.length > 0) {
      const nenabled = reduce(filter, (c, enabled) => (enabled ? c + 1 : c), 0)

      return (
        <div>
          <ul className="list-inline search__filters">
            <li>
              <Checkbox
                label={t('search.filter_user')}
                id="search-filter-user"
                value="user"
                checked={filter['user']}
                disabled={filter['user'] && nenabled === 1}
                align="right"
                onChange={checked => runUpdateFilter('user', checked)}
              />
            </li>
            <li>
              <Checkbox
                label={t('search.filter_case')}
                id="search-filter-case"
                value="case"
                checked={filter['case']}
                disabled={filter['case'] && nenabled === 1}
                align="right"
                onChange={checked => runUpdateFilter('case', checked)}
              />
            </li>
            <li>
              <Checkbox
                label={t('search.filter_group')}
                id="search-filter-group"
                value="group"
                checked={filter['group']}
                disabled={filter['group'] && nenabled === 1}
                align="right"
                onChange={checked => runUpdateFilter('group', checked)}
              />
            </li>
            <li>
              <Checkbox
                label={t('search.filter_learning')}
                id="search-filter-learning"
                value="learning"
                checked={filter['learning']}
                disabled={filter['learning'] && nenabled === 1}
                align="right"
                onChange={checked => runUpdateFilter('learning', checked)}
              />
            </li>
          </ul>
        </div>
      )
    }

    if (recentSearches.length > 0) {
      return (
        <span>
          Recent searches
          <button
            type="button"
            className="action-link small"
            style={{ margin: '0px 10px' }}
            onClick={() => clearRecentSearches()}
          >
            Clear
          </button>
        </span>
      )
    }

    return <span>Suggestions</span>
  }

  const renderList = () => {
    if (searchTerm.length > 2) {
      return (
        <div className="search__results" ref={searchResultsRef}>
          <ContentFeed
            key={JSON.stringify([searchTerm, filter])}
            t={t}
            feed_type={FEED_SEARCH_RESULTS}
            searchTerm={searchTerm}
            searchFilter={filter}
            paginate
            continuous
            cardStyle="row"
            cardProps={{ showCreator: true }}
            loadMoreLabel={t('search.load_more')}
            count={10}
            layout={{ _: 1 }}
            emptyComponent={NoResultsFound}
          />
        </div>
      )
    }

    if (recentSearches.length > 0) {
      return renderTermsList(recentSearches)
    }

    return renderTermsList(suggestedSearches)
  }

  const onClick = e => {
    if (
      /^(A)$/.test(e.target.tagName) &&
      /category-link/.test(e.target.className)
    ) {
      updateTerm(e.target.textContent)
    } else if (
      /^(A|IMG)$/.test(e.target.tagName) &&
      closest(e.target, el => /card-row/.test(el.className)) &&
      searchTerm.length > 0
    ) {
      saveRecentSearch(searchTerm)
      updateTerm('')
      closeModal()
    }
  }

  const renderTermsList = terms => {
    return (
      <div className="search__results" ref={searchStatusRef}>
        {(() => {
          return terms.map((term, i) => {
            return (
              <div
                className="card-row card-row--search"
                key={`${i}_search_term`}
              >
                <div className="media">
                  <div className="media__body">
                    <button
                      className="fake-link media__title"
                      onClick={() => selectTerm(term)}
                    >
                      {term}
                    </button>
                  </div>
                </div>
              </div>
            )
          })
        })()}
      </div>
    )
  }

  const styles = {
    titleAndButtonFlexSpacing: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  }

  return (
    <div className="search fs-unmask">
      {renderSearchBar()}
      <div className="search__status modal__prompt" ref={searchStatusRef}>
        <div
          className="modal__prompt-content"
          style={styles.titleAndButtonFlexSpacing}
        >
          {renderTitle()}
          <button
            onClick={() => {
              unfreezeBodyScroll()
              closeModal()
            }}
            className="btn not-palm"
          >
            Close
          </button>
        </div>
      </div>
      <ScrollTrap
        className="search__body modal__body"
        onClick={e => onClick(e)}
      >
        {renderList()}
      </ScrollTrap>
    </div>
  )
}

export const Search = connect(
  mapStateToPropsForEditor,
  mapDispatchToPropsForEditor
)(SearchView)

const NoResultsFound = () => {
  // TODO: fix for translation
  // const { t } = useTranslation('common')
  // t('search.no_results')

  return <div className="no-results">Sorry, no results found</div>
}

const mapStateToProps = state => {
  return {
    canSearch:
      selectIsAuthenticated(state) &&
      get(selectAuthenticatedUser(state), 'seniority') !== 'advisory_board_mod',
    searchTerm: selectSearchTerm(state),
    searchVisible: selectModalVisible(state, SearchModal),
    isMobile: selectIsMobile(state),
  }
}

const mapDispatchToProps = dispatch => ({
  showSearch() {
    dispatch(openModal(SearchModal))
  },
  closeModal() {
    dispatch(closeModal())
  },
  updateTerm(term) {
    dispatch(updateTerm(term))
  },
})

const ListingPageSearchBarView = props => {
  return (
    <SearchBarView {...props}>
      <FilterBar className="search-bar__status" />
    </SearchBarView>
  )
}

const ResourcePageSearchBarView = props => {
  return <SearchBarView {...props} />
}

export const ListingPageSearchBar = connect(
  mapStateToProps,
  mapDispatchToProps
)(ListingPageSearchBarView)

export const ResourcePageSearchBar = connect(
  mapStateToProps,
  mapDispatchToProps
)(ResourcePageSearchBarView)

// /////////////////////////////////////////////////////////////////////////////
// /////////////////////////////////////////////// WITH RESOURCE PAGE SEARCH BAR
export const withResourcePageSearchBar = (component, backUrl = undefined) => {
  const ResourcePageSearchBarPage = props => {
    const router = useRouter()
    const currentUser = useSelector(selectAuthenticatedUser)
    const componentProps = { ...props, router }

    const url =
      typeof backUrl === 'function'
        ? backUrl(componentProps)
        : typeof backUrl === 'string'
        ? backUrl
        : _reverseUrl(componentProps)

    return currentUser && currentUser.signup_product !== 'client-portal' ? (
      <div className="search-bar__parent-wrapper">
        <ResourcePageSearchBar>
          <Breadcrumb url={url} />
        </ResourcePageSearchBar>
        {createElement(component, {
          ...componentProps,
          key: 'ResourcePageSearchBar-' + url,
        })}
      </div>
    ) : (
      createElement(component, {
        ...componentProps,
        key: 'ResourcePageSearchBar-' + url,
      })
    )
  }

  return ResourcePageSearchBarPage
}

function _reverseUrl(props) {
  let url = ''

  if (props.router || props.route) {
    const prePath = get(props, 'router.previous', false)

    if (prePath && prePath === '/') {
      url = props.router.previous
    } else if (typeof window !== 'undefined') {
      url = getBackURLFromQuery(props.router.query)
    }
  }

  return url
}
