import React, { useState } from 'react'
import { Search } from './search'

export const SearchModal = () => {
  const [modalHeight, setModalHeight] = useState(0)
  const [containerHeight, setcontainerHeight] = useState(0)

  let _container: HTMLElement

  const refContainer = el => {
    _container = el
    setcontainerHeight(el ? el.offsetHeight : 0)
  }

  const updateHeight = (height: number): void => {
    if (height !== undefined && height !== modalHeight) {
      setModalHeight(height)
    }
  }

  return (
    <div
      className="fs-unmask modal-container modal-container--search"
      ref={refContainer}
    >
      <div className="modal-container__content page__container">
        <div
          className="modal modal--search"
          style={{
            bottom:
              _container && modalHeight
                ? Math.max(
                    0,
                    _container.offsetHeight - Math.max(400, modalHeight)
                  )
                : 0,
          }}
        >
          <Search onHeightChange={updateHeight} />
        </div>
      </div>
    </div>
  )
}
