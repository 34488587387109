import React from 'react'
import { path } from 'ramda'
// ////////////////////////////////////////////////////////////////// COMPONENTS
import { closest } from '../lib/dom'
// based on https://github.com/guillaumervls/react-infinite-scroll
// /////////////////////////////////////////////////////////////////////// UTILS
import noop from '../utils/noop'
// ////////////////////////////////////////////////////////////////// FLOW TYPES

// ////////////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////////////

type Props = {
  page: number
  hasMore: boolean
  loadMore: Function
  threshold: number
  loading: boolean
  loader: any
  children: React.ReactNode
}

class InfiniteScroll extends React.Component<Props, any> {
  _scrollContainer: any
  _contentElement: any
  _scrollEventSource: any
  _defaultLoader: any

  static defaultProps = {
    page: 1,
    hasMore: false,
    loadMore: noop,
    threshold: 250,
    loading: false,
  }

  constructor(props: Props, context: any) {
    super(props, context)
  }

  componentDidMount() {
    this.attachScrollListener()
  }

  componentDidUpdate() {
    this.attachScrollListener()
  }

  componentWillUnmount() {
    this.detachScrollListener()
  }

  scrollListener = (): any => {
    const scrollTop =
      this._scrollContainer === document.documentElement
        ? window.pageYOffset !== undefined
          ? window.pageYOffset
          : path(
              ['scrollTop'],
              document.documentElement ||
                document.body.parentNode ||
                document.body
            )
        : this._scrollContainer.scrollTop
    const height = window.innerHeight
    const { _contentElement, topPosition } = this
    if (
      topPosition(_contentElement) +
        _contentElement.offsetHeight -
        scrollTop -
        height <
      Number(this.props.threshold)
    ) {
      this.detachScrollListener()
      if (!this.props.loading) {
        // call loadMore after detachScrollListener to allow
        // for non-async loadMore functions
        this.props.loadMore(this.props.page + 1)
      }
    }
  }

  attachScrollListener = (): any => {
    if (!this.props.hasMore || this.props.loading) {
      return
    }
    this._scrollEventSource.addEventListener('scroll', this.scrollListener)
    window.addEventListener('resize', this.scrollListener)
    this.scrollListener()
  }

  detachScrollListener = (): any => {
    this._scrollEventSource.removeEventListener('scroll', this.scrollListener)
    window.removeEventListener('resize', this.scrollListener)
  }

  setRef = (el: any): any => {
    this._contentElement = el
    this._scrollContainer = closest(el, (el: any): any => {
      return (
        getComputedStyle(el).overflowY === 'auto' ||
        el === document.documentElement
      )
    })
    this._scrollEventSource =
      this._scrollContainer === document.documentElement
        ? window
        : this._scrollContainer
  }

  setDefaultLoader = (loader: any): any => {
    this._defaultLoader = loader
  }

  topPosition = (domElt: any): any => {
    if (!domElt) {
      return 0
    }
    const position = getComputedStyle(domElt).position
    if (position === 'absolute' || position === 'fixed') {
      return 0
    }
    return domElt.offsetTop + this.topPosition(domElt.offsetParent)
  }

  render(): React.ReactElement {
    const { children, hasMore, loader } = this.props

    return (
      <div ref={(el: any): any => this.setRef(el)}>
        {children} {hasMore ? loader || this._defaultLoader : null}
      </div>
    )
  }
}

// /////////////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////// EXPORT DEFAULT
export default InfiniteScroll
