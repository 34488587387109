import { useTranslation } from 'next-i18next'
import React from 'react'

import { TextLink } from './link'
import { ArrowIcons } from './icons'

interface Props {
  url?: string
  messageID?: string
}

const Breadcrumb = ({ url, messageID }: Props) => {
  const { t } = useTranslation('common')

  return url ? (
    <TextLink to={url} className="breadcrumb">
      <ArrowIcons
        position="left"
        title="Left"
        width={12}
        className="breadcrumb__icon"
      />

      {messageID && <span className="breadcrumb__label">{t(messageID)}</span>}
    </TextLink>
  ) : null
}

export default Breadcrumb
