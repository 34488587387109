import cs from 'classnames'
import React from 'react'
import SearchInputBox from '../../ui/search-input-box'

function freezeBodyScroll() {
  document.body.classList.add('body__freeze-scroll')
}

type SearchModalProps = {
  children: React.ReactNode
  showSearch?: Function
  closeModal?: Function
  updateTerm: Function
  searchTerm?: string
  searchVisible?: boolean
  canSearch?: boolean
  isMobile?: boolean
}
export const SearchBarView = ({
  children,
  showSearch,
  closeModal,
  updateTerm,
  searchTerm,
  searchVisible,
  canSearch,
}: SearchModalProps) => {
  const onFocus = () => {
    if (!searchVisible) {
      freezeBodyScroll()
      showSearch()
    }
  }

  const onChange = e => {
    updateTerm(e.target.value)
  }

  const onClear = () => {
    updateTerm('')
  }

  const styles = {
    container: cs('search-bar', 'dark-background', {
      'search-bar--focused': searchVisible,
    }),
  }

  return (
    <div className={cs('fs-unmask', styles.container)}>
      <div className={cs('page__container', 'search-bar__container')}>
        {children}
        {canSearch ? (
          <div className="search-bar__search">
            <div className="search-bar__form">
              <SearchInputBox
                onFocus={onFocus}
                onChange={onChange}
                value={searchTerm}
                onClearInputClick={onClear}
              />
              <div className="accessibility">
                <button type="submit" className="btn" />
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  )
}
