import { useTranslation } from 'next-i18next'
import React from 'react'
import { connect } from 'react-redux'

import * as modal from '../../modules/modal/actions'
import { selectSpecialtyMapper } from '../../modules/categories/selectors'
import { FilterTagPickerModal } from '../../routes/components/tag-picker'
import TextButton from '../../ui/buttons/text'

interface FilterBarProps {
  className: string
  filters: any
  specialties?: any
  pickSpecialties: () => void
}

const FilterBar = ({
  className,
  specialties,
  filters,
  pickSpecialties,
}: FilterBarProps) => {
  const specialty = specialtyLabel(specialties, filters)
  const { t } = useTranslation()

  return (
    <div className={className}>
      {t('common:search.current_filters', { specialty: specialty })}
      <span className="sep"> | </span>
      <TextButton onClick={pickSpecialties}>
        {t('common:search.filter_button_label')}
      </TextButton>
    </div>
  )
}

function specialtyLabel(specialties, filters) {
  let label
  if (specialties && filters) {
    label = specialties.getLabelFor(
      specialties.findPrimaryFilterCategory(filters)
    )
  }
  if (!label) {
    label = 'all'
  }
  return label
}

function mapStateToProps(state) {
  return {
    filters: state.filters,
    specialties: selectSpecialtyMapper(state),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    pickSpecialties: function () {
      dispatch(modal.openModal(FilterTagPickerModal))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterBar)
